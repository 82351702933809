import { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage, IntlConfig, IntlProvider, ReactIntlErrorCode } from 'react-intl';

import { MessageKey, MESSAGES } from './setup';

export const TRANSLATIONS_SUPPORTED = ['de', 'en', 'es', 'fi', 'fr', 'it', 'ja', 'nb', 'sv', 'da'] as const;
export const LOCALE_DEFAULT = 'en-US';
export const TRANSLATION_DEFAULT = 'en';
export const LOCALE_FORMATS: IntlConfig['defaultFormats'] = {
  date: {
    default: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  },
};

export type TranslationsSupported = (typeof TRANSLATIONS_SUPPORTED)[number];

export function isSupportedLanguage(locale: string): locale is TranslationsSupported {
  return TRANSLATIONS_SUPPORTED.includes(locale as TranslationsSupported);
}

export const getMessagesForLocale = (locale: TranslationsSupported) => MESSAGES[locale];

export const getTranslationLanguage = (): TranslationsSupported => {
  // Dig out the locale from accept-language header, then extract 2 char language code out of it
  const browserLocale = window.navigator.language ?? LOCALE_DEFAULT;
  const languageCode = new Intl.Locale(browserLocale).language;
  // Fall back to en if not supported language found
  if (!isSupportedLanguage(languageCode)) {
    return TRANSLATION_DEFAULT;
  }
  return languageCode;
};

export function LocaleProvider({ children }: PropsWithChildren) {
  const locale = window.navigator.language ?? LOCALE_DEFAULT;
  const translationLanguage = getTranslationLanguage();
  const messages = getMessagesForLocale(translationLanguage);

  return (
    <IntlProvider
      onError={(err) => {
        if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION || err.code === ReactIntlErrorCode.MISSING_DATA) {
          throw new Error('Error:' + err.message);
        }
        throw err;
      }}
      messages={messages}
      locale={locale}
      formats={LOCALE_FORMATS}
      defaultLocale={LOCALE_DEFAULT}
    >
      {children}
    </IntlProvider>
  );
}

/**
 * This is built over React-Intl's <FormattedMessage /> for convenience
 * @example
 * t("guide_infotext_sleep_score", { score })
 */
export function t(id: MessageKey, values?: Record<string, ReactNode | ((chunks: ReactNode) => ReactNode)>) {
  return <FormattedMessage id={id} values={values} />;
}
